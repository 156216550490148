import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "input-container" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_input_group = _resolveComponent("b-input-group")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_card, { class: "center-container" }, {
      default: _withCtx(() => [
        _createElementVNode("form", _hoisted_1, [
          _createVNode(_component_b_row, { class: "justify-content-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, {
                md: "12",
                xl: "4"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('label.loginId')) + "*", 1),
                  _createVNode(_component_b_form_input, {
                    class: "input",
                    placeholder: _ctx.$t('label.loginId'),
                    modelValue: _ctx.loginId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginId) = $event)),
                    required: ""
                  }, null, 8, ["placeholder", "modelValue"])
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_row, { class: "justify-content-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, {
                md: "12",
                xl: "4"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('label.password')) + "*", 1),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_b_input_group, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_input, {
                          class: "input",
                          type: _ctx.showPassword ? 'text' : 'password',
                          placeholder: _ctx.$t('label.password'),
                          modelValue: _ctx.password,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                          required: ""
                        }, null, 8, ["type", "placeholder", "modelValue"]),
                        _createVNode(_component_b_button, {
                          variant: "light",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
                        }, {
                          default: _withCtx(() => [
                            (_ctx.showPassword)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('button.hidden')), 1))
                              : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('button.display')), 1))
                          ], undefined, true),
                          _: 1
                        })
                      ], undefined, true),
                      _: 1
                    })
                  ])
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_row, { class: "justify-content-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, {
                md: "12",
                xl: "4",
                class: "text-right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_button, {
                    variant: "primary",
                    class: "login-button",
                    onClick: _ctx.login,
                    disabled: !_ctx.loginId || !_ctx.password
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button.login')), 1)
                    ], undefined, true),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ])
      ], undefined, true),
      _: 1
    })
  ]))
}