
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue'
import { ApolloError } from 'apollo-client'
import { Hub, Auth, Amplify } from 'aws-amplify'
import { defineComponent, toRefs, watch, ref } from 'vue'
import { useStore } from 'vuex'

import LoginForm from './components/organisms/c/LoginForm.vue'
import store from './store'

import LogoutHeader from '@/components/organisms/c/LogoutHeader.vue'
import { OperationLogMessageInput } from '@/types/generated/graphql'
import apiClient from '@/utils/apiClient'
import { LOGIN_ID_JOINT } from '@/utils/constant'
import Toast, { genNotifyMessage } from '@/utils/toast'
import i18n, { COGNITO_CODE_MAP } from '@/vue-i18n'

export default defineComponent({
  // components: { AccessErrorView },
  data() {
    return {
      loginId: '',
      password: '',
      newPassword: '',
      confirmedPassword: '',
      kigyoId: '',
    }
  },
  components: {
    LoginForm,
    LogoutHeader,
  },
  setup(props, ctx) {
    // ref: https://ui.docs.amplify.aws/vue/connected-components/authenticator/advanced
    const { auth, route, user, signOut, authStatus } = toRefs(
      useAuthenticator()
    )
    watch(authStatus, async (newValue) => {
      console.log('authState', newValue)
      if (newValue === 'authenticated') {
        // 認証状態をcommit
        store.commit('auth/signin', Object.assign({}, user.value))
        try {
          await fetchUserData()
        } catch (error) {
          console.error('Error fetching user data:', error)
        }
      }
    })
    const fetchUserData = async () => {
      if (store.state.auth.authorization) {
        try {
          await apiClient.getLoginUser(this)
          await apiClient.getKigyoActionSettingMst(this)
          await apiClient.getTorihikisakiList(this)

          if (!store.state.app.loginUser) {
            throw new Error('Not get login user')
          }
          if (!store.state.app.kigyoActionSetting) {
            throw new Error('Not get kigyo action setting mst')
          }
          if (
            !store.state.app.torihikisakiList ||
            !store.state.app.torihikisakiList.length
          ) {
            throw new Error('Not get torihikisaki list')
          }
        } catch (e) {
          if (e === 'Not get login user') {
            alert(i18n.global.t('error.notGetLoginUser'))
          }
          if (e === 'Not get kigyo action setting mst') {
            alert(i18n.global.t('error.notGetKigyoActionSettingMst'))
          }
          if (e === 'Not get torihikisaki list') {
            alert(i18n.global.t('error.notGetTorihikisakiList'))
          } else {
            alert(i18n.global.t('error.notGetLoginUser'))
          }
          store.dispatch('auth/logout')
        }
      }
    }
    const fetchSystemStatus = async () => {
      console.log('fetchSystemStatus')
      if (store.state.auth.authorization) {
        try {
          await apiClient.getSystemStatus(this)
          if (!store.state.app.systemStatus) {
            throw new Error('Not get system status')
          }
        } catch (e: unknown) {
          alert(i18n.global.t('error.notGetSystemStatus'))
          store.dispatch('auth/logout')
        }
      }
    }
    const fetchKigyoActionSettingMst = async () => {
      if (store.state.auth.authorization) {
        try {
          await apiClient.getKigyoActionSettingMst(this)
          if (!store.state.app.kigyoActionSetting) {
            throw new Error('Not get kigyo action setting mst')
          }
        } catch (e: unknown) {
          alert(i18n.global.t('error.notGetKigyoActionSettingMst'))
          store.dispatch('auth/logout')
        }
      }
    }
    const fetchTorihikisakiList = async () => {
      if (store.state.auth.authorization) {
        try {
          await apiClient.getTorihikisakiList(this)
          if (
            !store.state.app.torihikisakiList ||
            !store.state.app.torihikisakiList.length
          ) {
            throw new Error('Not get torihikisaki list')
          }
        } catch (e: unknown) {
          alert(i18n.global.t('error.notGetTorihikisakiList'))
          store.dispatch('auth/logout')
        }
      }
    }
    const showConfirmedPassword = ref(false)
    const showNewPassword = ref(false)
    const newPasswordErrorMessage = ref('')

    return {
      route,
      user,
      signOut,
      authStatus,
      showConfirmedPassword,
      showNewPassword,
      newPasswordErrorMessage,
      fetchUserData,
      fetchSystemStatus,
      fetchKigyoActionSettingMst,
      fetchTorihikisakiList,
    }
  },
  created() {
    const queryParams = new URLSearchParams(window.location.search)
    this.kigyoId = queryParams.get('kigyoid')
    this.fetchSystemStatus()
    this.fetchKigyoActionSettingMst()
    this.fetchUserData()
    this.fetchTorihikisakiList()
  },
  computed: {
    newPasswordState() {
      // 初期表示・未入力の場合はnullを返す
      if (this.newPassword.length == 0) {
        return null
      }
      const pattern = /^\s/
      return this.newPassword.length >= 6 && !pattern.test(this.newPassword)
    },
    confirmedPasswordState() {
      // 初期表示・未入力の場合はnullを返す
      if (this.confirmedPassword.length == 0) {
        return null
      }
      return this.confirmedPassword === this.newPassword
    },
    newPasswordValidationMessage() {
      // 先頭に空白が入っている場合エラー
      const pattern = /^\s/
      if (pattern.test(this.newPassword)) {
        return i18n.global.t('error.passwordEmptyStart')
      }
      if (this.newPassword.length < 6) {
        return i18n.global.t('error.changePasswordPolicyException')
      }
      return ''
    },
    confirmedPasswordValidationMessage() {
      if (this.confirmedPassword != this.newPassword) {
        return i18n.global.t('error.passwordMismatch')
      }
      return ''
    },
  },
  mounted() {
    Hub.listen('auth', (res) => {
      console.log('Hub.listen', res)
      const { event } = res.payload
      if (res.payload.data) {
        const { code } = res.payload.data
        let message = res.payload.data.message
        if (Object.keys(COGNITO_CODE_MAP).includes(event)) {
          // @ts-ignore if can be checked
          if (Object.keys(COGNITO_CODE_MAP[event]).includes(code)) {
            // @ts-ignore if can be checked
            message = this.$i18n.t(COGNITO_CODE_MAP[event][code])
          }
        }
        Toast.error(this, { message })
      }
    })
  },
  beforeUnmount() {
    this.fetchUserData()
    this.fetchSystemStatus()
    this.fetchKigyoActionSettingMst()
    this.fetchTorihikisakiList()
  },
  methods: {
    async login(loginInfo: { loginId: string; password: string }) {
      if (!this.kigyoId) {
        Toast.error(this, {
          message: this.$t('error.noKigyoId'),
        })
        return
      }
      try {
        this.loginId = this.kigyoId + LOGIN_ID_JOINT + loginInfo.loginId
        this.password = loginInfo.password
        const user = await Auth.signIn(this.loginId, this.password)
        store.commit('auth/signin', user)
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // 強制パスワード変更処理　モーダル表示？
          this.$refs['change-password-modal'].show()
        } else {
          try {
            const input = {
              logType: 'LOGIN_SUCCESS',
              parameter: {},
            } as OperationLogMessageInput
            const { data } = await apiClient.mutationPutOperationLog(input)
          } catch (e) {
            console.error(e)
          }
          // 入力項目のリセット
          this.loginId = ''
          this.password = ''
          this.$refs.loginFormRef.resetInput()

          this.fetchSystemStatus()
          this.fetchTorihikisakiList()
        }
      } catch (e) {
        console.error(e)
      }
    },
    async changeNewPassword() {
      const newPassword = this.newPassword
      // ref: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#change-password
      const user = await Auth.signIn(this.loginId, this.password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        try {
          const newUser = await Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword // the new password
          ).catch((e) => {
            console.error(e)
          })

          try {
            const input = {
              logType: 'LOGIN_SUCCESS',
              parameter: {},
            } as OperationLogMessageInput
            const { data } = await apiClient.mutationPutOperationLog(input)
          } catch (e) {
            console.error(e)
          }
          // パスワード変更モーダルの入力項目を初期化
          this.$refs['change-password-modal'].hide()
          this.loginId = ''
          this.password = ''
          this.$refs.loginFormRef.resetInput()

          this.newPassword = ''
          this.confirmedPassword = ''
          this.showNewPassword = false
          this.showConfirmedPassword = false

          const { data } = await apiClient.mutationUpdateChangePassword()
          const { success, message } = data.updateChangePassword

          if (!success) {
            Toast.error(this, {
              message: message,
            })
            return
          }

          // 無事変更できたらユーザー情報を取得して画面遷移
          store.commit('auth/signin', user)
          Toast.success(this, {
            message: i18n.global.t('description.successChangePassword'),
          })

          this.fetchSystemStatus()
          this.fetchTorihikisakiList()
        } catch (e) {
          console.error(e)
        }
      }
    },
  },
})
