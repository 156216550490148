
import { defineComponent, ref, watch } from 'vue'

import store from '@/store'

export default defineComponent({
  setup(props, ctx) {
    const loginId = ref('')
    const password = ref('')
    const login = () => {
      const loginInfo = {
        loginId: loginId.value,
        password: password.value,
      }
      ctx.emit('login', loginInfo)
    }
    const showPassword = ref(false)

    const resetInput = () => {
      loginId.value = ''
      password.value = ''
      showPassword.value = false
    }
    return {
      login,
      resetInput,
      loginId,
      password,
      showPassword,
    }
  },
})
