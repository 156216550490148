import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { createRouterLayout } from 'vue-router-layout'

import store from './store'

const RouterLayout = createRouterLayout((layout) => {
  return import('@/layouts/' + layout + '.vue')
})

const children = [
  {
    name: 'c-settings',
    path: 'c/settings',
    component: () => import('@/pages/c/settings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'c-maintenance',
    path: 'c/maintenance',
    component: () => import('@/pages/c/maintenance.vue'),
    meta: { requiresMaintenance: true },
  },
  {
    name: 'v-contractProduct',
    path: 'v/contractProduct',
    component: () => import('@/pages/v/contractProduct.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'm-riyoshaSetting',
    path: 'm/riyoshaSetting',
    component: () => import('@/pages/m/RiyoshaSetting.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      requiresEditRiyosha: true,
    },
  },
  //  1ユーザーだけ選択した場合の利用者ログイン情報
  {
    name: 'm-riyoshaLoginReport',
    path: 'm/riyoshaLoginReport',
    component: () => import('@/pages/m/RiyoshaLoginReport.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      requiresEditRiyosha: true,
    },
  },
  // 複数選択した場合の利用者ログイン情報
  {
    name: 'm-riyoshasLoginReport',
    path: 'm/riyoshasLoginReport',
    component: () => import('@/pages/m/RiyoshasLoginReport.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      requiresEditRiyosha: true,
    },
  },
  {
    name: 'm-kigyoLoginReport',
    path: 'm/kigyoLoginReport',
    component: () => import('@/pages/m/KigyoLoginReport.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      requiresEditRiyosha: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: children,
    },
  ],
})

console.log('router initialize')

router.beforeEach(async (to, from) => {
  console.log('router beforeEach')
  const isRequiredAuth = to.matched.some((record) => record.meta.requiresAuth)
  console.log('isRequiredAuth=', isRequiredAuth)
  console.log('to.fullPath=', to.fullPath)
  const kigyoId = await fetchKigyoIdFromStore()
  const isSystemMaintenance = await fetchSystemStatusFromStore()

  try {
    // メンテナンス中の場合
    if (isSystemMaintenance) {
      if (to.path === '/c/maintenance') {
        return
      } else {
        router.push({ path: '/c/maintenance', query: { kigyoid: kigyoId } })
        return
      }
    }

    let noAuth = false
    try {
      if (!store.state.auth.authorization) {
        // localStorage に有効なセッションを持っているか
        await store.dispatch('auth/checkAlreadyLogin')
        if (store.state.auth.authorization) {
          console.log('- session already has')
        } else {
          noAuth = true
          console.log('- no session')
        }
      }
    } catch (err) {
      console.log('- couldnt get auth/systeminfo')
      console.log(err)
      noAuth = true
    }

    // 有効なセッションがなかった場合にはそのまま遷移（遷移先でログイン画面を表示）
    if (noAuth) {
      if (isRedirectCandidate(to)) {
        console.log('- redirect to home with no auth')
        router.push({ path: redirectTop(), query: { kigyoid: kigyoId } })
        return
      } else {
        // next()
        return
      }
    } else {
      if (to.path === '/c/maintenance' || to.path === '/') {
        router.push({ path: redirectTop(), query: { kigyoid: kigyoId } })
        // next({ path: redirectTop() })
        return
      }
    }

    // 管理者権限が必要なページの場合
    const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin)
    const isAdmin = store.getters['app/isAdmin']
    if (requiresAdmin && !isAdmin) {
      // next({ path: path }) // アクセスできるページにリダイレクト
      router.push({ path: redirectTop(), query: { kigyoid: kigyoId } })
      return
    }

    const requiresEditRiyosha = to.matched.some(
      (record) => record.meta.requiresEditRiyosha
    )
    if (requiresEditRiyosha && !store.getters['app/isEditRiyosha']) {
      router.push({ path: redirectTop(), query: { kigyoid: kigyoId } })
      return
    } else {
      return
    }
  } catch (e) {
    // システム情報が取得できないのでメンテモード
    // next({ path: '/c/maintenance' })
    // next()
  }
})

// 企業IDが取れるまで再試行する
async function fetchKigyoIdFromStore() {
  let kigyoId = store.state.app.loginUser?.kigyoIdIndex

  while (!kigyoId) {
    await new Promise((resolve) => setTimeout(resolve, 100))
    kigyoId = store.state.app.loginUser?.kigyoIdIndex
  }

  return kigyoId
}

// システムステータスが取れるまで再試行する
async function fetchSystemStatusFromStore() {
  await store.dispatch('app/getSystemStatus')
  let systemStatus = store.state.app.systemStatus

  while (!systemStatus) {
    await new Promise((resolve) => setTimeout(resolve, 100))
    systemStatus = store.state.app.systemStatus
  }
  return store.getters['app/isSystemMaintenance']
}

const redirectList = ['/c/maintenance']
/**
 * リダイレクト対象
 * @param to
 * @returns
 */

function isRedirectCandidate(to: any) {
  return redirectList.includes(to.fullPath)
}

/**
 * 権限に応じてトップ（ホーム）に移動
 * @param next
 */
export function redirectTop() {
  if (store.getters['app/isAdmin'] && store.getters['app/isEditRiyosha']) {
    return '/m/riyoshaSetting'
  } else {
    return '/v/contractProduct'
  }
}

export default router
