import { translations } from '@aws-amplify/ui-vue'
import { I18n } from 'aws-amplify'
import dayjs from 'dayjs'
import { createI18n } from 'vue-i18n'

import ja from '@/locales/ja.json'
// 1. Ready translated locale messages
// The structure of the locale message is the hierarchical object structure with each locale as the top property
dayjs.locale('ja', {
  weekdays: [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
  ],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
})

I18n.putVocabularies(translations)
I18n.setLanguage('ja')

export const COGNITO_CODE_MAP = {
  signIn_failure: {
    UserNotFoundException: 'error.invalidUserIdOrPassword',
    NotAuthorizedException: 'error.invalidUserIdOrPassword',
    PasswordResetRequiredException: 'error.PasswordResetRequired',
    LimitExceededException: 'error.limitExceeded',
    InvalidParameterException: 'error.invalidPassword',
  },
  forgotPassword_failure: {
    UserNotFoundException: 'error.userNotFound',
    InvalidPasswordException: 'error.invalidPassword',
    LimitExceededException: 'error.limitExceeded',
    InvalidParameterException: 'error.forgotPasswordfailureInvalidParameter',
  },
  completeNewPassword_failure: {
    InvalidPasswordException: 'error.invalidPassword',
    InvalidParameterException: 'error.invalidPassword',
  },
  forgotPasswordSubmit_failure: {
    CodeMismatchException: 'error.codeMismatch',
    InvalidPasswordException: 'error.invalidPassword',
    LimitExceededException: 'error.limitExceeded',
  },
  tokenRefresh_failure: {
    NotAuthorizedException: 'error.invalidRefreshToken',
  },
}

// 2. Create i18n instance with options
const i18n = createI18n({
  locale: 'ja', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: { ja: ja }, // set locale messages
  // messages: messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})
export default i18n
