import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { createApolloProvider } from '@vue/apollo-option'
import { ApolloLink, concat, split } from 'apollo-link'
import { Amplify, Auth } from 'aws-amplify'

import awsExports from './aws-exports'

Amplify.configure(awsExports)

const cache = new InMemoryCache()

const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: awsExports.API.endpoints[0].endpoint || 'http://localhost:4000',
  // uri: 'http://localhost:4000',
})

// headerの設定　TODO:システム設定だけは認証なしで取得する
const authLink = setContext(async (_, { headers }) => {
  const token = await defaultGetAuth().then((value) => value)

  // const operationName = _.operationName
  // console.log(operationName)
  // if (operationName === 'systemStatus') {
  //   return {
  //     headers: {
  //       ...headers,
  //       'x-api-key': 'aaaa',
  //     },
  //   }
  // } else {
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
    // }
  }
})

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
})

// Create a provider
export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
  defaultOptions: {
    // apollo options applied to all queries in components
    $query: {
      fetchPolicy: 'cache-and-network',
    },
  },
})

// Manually call this when user log out
export async function onLogout() {
  // dont use websocket
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.stop()
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(
      '%cError on cache reset (logout)',
      'color: orange;',
      (e as Error).message
    )
  }
}

/**
 * Authorization header の設定
 * @returns
 */
async function defaultGetAuth() {
  try {
    const currentSession = await Auth.currentSession()
    const idToken = currentSession.getIdToken()
    const jwtToken = idToken.getJwtToken()
    return jwtToken ? `Bearer ${jwtToken}` : ''
  } catch (e) {
    console.info('error getting jwt', e)
    return ''
  }
}

/**
 * Query のキャッシュID取得
 * @param queryName `query name : ex uiNormalOrderDispItems`
 * @param queryParam request params
 * @returns
 */
export function getQueryCacheId(queryName: string, queryParam: any) {
  const q = `${queryName}(${JSON.stringify(queryParam, stringifyReplacer)})`
  return q
}
export function getCustomCacheId(queryName: string, queryParam: any) {
  const q = `${queryName}:${JSON.stringify(queryParam)}`
  return q
}
function stringifyReplacer(_key: string, value: any): any {
  if (value && typeof value === 'object' && !Array.isArray(value)) {
    value = Object.keys(value)
      .sort()
      .reduce((copy, key) => {
        copy[key] = value[key]
        return copy
      }, {} as Record<string, any>)
  }
  return value
}

/**
 * キャッシュの削除
 * 特定IDを消したい場合は ID を指定、Query 全体なら ROOT_QUERY
 * @param id
 * @param fieldName
 */
export function removeCache(opt: { id: string; fieldName?: string }) {
  cache.evict(opt)
}

export default apolloProvider
