
import Vue, { defineComponent, PropType } from 'vue'
import { mapState, mapGetters } from 'vuex'

import Breadcrumb from './Breadcrumb.vue'

// import { AvailableStore } from '@/types/entities'
import config from '@/utils/config'
import { pathGroup } from '@/utils/constant'
import Toast from '@/utils/toast'

/**
 * アプリケーションヘッダ
 *
 * 店舗は背景青、本部は背景黒
 *
 * サービス名は config で変更可能
 */
export default defineComponent({
  computed: {
    serviceName() {
      return config.serviceName
    },
  },
  data() {
    return {
      serviceTopUrl: config.serviceUrl,
    }
  },
})
