import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4467780c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app" }
const _hoisted_2 = { class: "login" }
const _hoisted_3 = { class: "password-requirement" }
const _hoisted_4 = { class: "form" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "input-container" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "input-container" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LogoutHeader = _resolveComponent("LogoutHeader")!
  const _component_login_form = _resolveComponent("login-form")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form_invalid_feedback = _resolveComponent("b-form-invalid-feedback")!
  const _component_b_input_group = _resolveComponent("b-input-group")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_router_view)
    ], 512), [
      [_vShow, _ctx.authStatus == 'authenticated']
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LogoutHeader),
      _createVNode(_component_login_form, {
        onLogin: _ctx.login,
        ref: "loginFormRef"
      }, null, 8, ["onLogin"]),
      _createElementVNode("div", null, [
        _createVNode(_component_b_modal, {
          centered: "",
          ref: "change-password-modal",
          title: _ctx.$t('description.changeInitialPassword'),
          "ok-variant": "outline-dark",
          size: "lg"
        }, {
          "modal-footer": _withCtx(({ cancel }) => [
            _createVNode(_component_b_button, {
              variant: "light",
              onClick: ($event: any) => (cancel())
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('button.close')), 1)
              ], undefined, true),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_b_button, {
              variant: "primary",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeNewPassword())),
              disabled: 
                !_ctx.newPassword ||
                !_ctx.confirmedPassword ||
                !_ctx.newPasswordState ||
                !_ctx.confirmedPasswordState
              
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('button.change-password')), 1)
              ], undefined, true),
              _: 1
            }, 8, ["disabled"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, _toDisplayString(_ctx.$t('description.changePasswordPolicy')), 1)
                ])
              ]),
              _createElementVNode("form", _hoisted_4, [
                _createVNode(_component_b_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_col, { sm: "12" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('label.newPassword')) + "*", 1),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_b_input_group, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_form_input, {
                                class: "new-password-input input",
                                type: _ctx.showNewPassword ? 'text' : 'password',
                                placeholder: _ctx.$t('label.newPassword'),
                                modelValue: _ctx.newPassword,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPassword) = $event)),
                                state: _ctx.newPasswordState,
                                "aria-describedby": "new-password-input-live-feedback",
                                required: ""
                              }, null, 8, ["type", "placeholder", "modelValue", "state"]),
                              _createVNode(_component_b_button, {
                                variant: "light",
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showNewPassword = !_ctx.showNewPassword))
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.showNewPassword)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('button.hidden')), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('button.display')), 1))
                                ], undefined, true),
                                _: 1
                              }),
                              _createVNode(_component_b_form_invalid_feedback, { id: "new-password-input-live-feedback" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.newPasswordValidationMessage), 1)
                                ], undefined, true),
                                _: 1
                              })
                            ], undefined, true),
                            _: 1
                          })
                        ])
                      ], undefined, true),
                      _: 1
                    }),
                    _createVNode(_component_b_col, { sm: "12" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('label.confirmedPassword')) + "*", 1),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_b_input_group, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_form_input, {
                                class: "confirmed-password-input input",
                                "aria-describedby": "confirmed-password-input-live-feedback",
                                type: _ctx.showConfirmedPassword ? 'text' : 'password',
                                placeholder: _ctx.$t('label.confirmedPassword'),
                                modelValue: _ctx.confirmedPassword,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmedPassword) = $event)),
                                state: _ctx.confirmedPasswordState,
                                required: ""
                              }, null, 8, ["type", "placeholder", "modelValue", "state"]),
                              _createVNode(_component_b_button, {
                                variant: "light",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showConfirmedPassword = !_ctx.showConfirmedPassword))
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.showConfirmedPassword)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('button.hidden')), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('button.display')), 1))
                                ], undefined, true),
                                _: 1
                              }),
                              _createVNode(_component_b_form_invalid_feedback, { id: "confirmed-password-input-live-feedback" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.confirmedPasswordValidationMessage), 1)
                                ], undefined, true),
                                _: 1
                              })
                            ], undefined, true),
                            _: 1
                          })
                        ])
                      ], undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                })
              ])
            ])
          ], undefined, true),
          _: 1
        }, 8, ["title"])
      ])
    ], 512), [
      [_vShow, _ctx.authStatus != 'authenticated']
    ])
  ]))
}