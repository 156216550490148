import { createStore } from 'vuex'

import app from './modules/app'
import auth from './modules/auth'

const store = createStore({
  modules: {
    app,
    auth,
  },
})

// カスタムモジュールの読み込み
// function loadExModules() {
//   if (!process.env['COMPANY']) {
//     return
//   }
//   try {
//     const modules = require.context('./x', true, /[A-Za-z0-9-_,\s]+\.ts$/i)

//     modules.keys().forEach((key) => {
//       const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//       if (matched && matched.length > 1) {
//         const modulenameName = matched[1]
//         store.registerModule(modulenameName, modules(key).default)
//       }
//     })
//   } catch (e) {
//     console.log('no ex modules')
//   }
// }
// loadExModules()

export default store
