const awsmobile = {
  Auth: {
    // フェデレーションアイデンティティのID
    identityPoolId: 'ap-northeast-1:384fa118-2e80-4b11-958d-5fe1027d4c4e',
    // リージョン
    region: 'ap-northeast-1',
    // ユーザープールのID
    userPoolId: 'ap-northeast-1_TxjNCdRbZ',
    // ユーザープールのウェブクライアントID
    userPoolWebClientId: '4r9bm5gjqeh5uktq8jla93dl3a',
    // mandatorySignIn: true
  },
  API: {
    endpoints: [
      {
        name: 'uniformweb-api',
        // eslint-disable-next-line
        endpoint: 'https://t6yirme6qnghziemurefvuvwoy.appsync-api.ap-northeast-1.amazonaws.com/graphql',
        region: 'ap-northeast-1',
      },
    ],
  },
}
export default awsmobile
