import { Amplify, Auth } from 'aws-amplify'

import awsConfig from '@/aws-exports'
import router from '@/router'
import { AuthState, AuthMutations, Actions } from '@/types/entities'
import { Riyosha } from '@/types/generated/graphql'
import { onLogout } from '@/vue-apollo'
import i18n from '@/vue-i18n'

function getDefaultState() {
  return {
    // Variable value
    loadingIds: [],
    authorization: false,
    loginUserInfo: {
      name: '',
    },
  }
}
const state: AuthState = getDefaultState()

const getters = {
  isChangePasswordLoading(state: AuthState) {
    const ret = !state.loadingIds.indexOf('changePassword')
    return ret
  },
}

const mutations: AuthMutations = {
  startLoading: (state: AuthState, Id: string) => {
    state.loadingIds.push(Id)
  },
  endLoading: (state: AuthState, Id: string) => {
    state.loadingIds = state.loadingIds.filter(function (Ids: string) {
      return Ids !== Id
    })
  },

  signin: (state: AuthState, loginUser: Riyosha) => {
    state.authorization = true
    state.loginUserInfo = loginUser
  },

  setUserAttribute: (state: AuthState, attributes) => {
    state.loginUserInfo.attributes = attributes
  },

  logout: (state: AuthState) => {
    Object.assign(state, getDefaultState())
  },

  // updateSession: (state: AuthState, session) => {
  //   console.log(session)
  //   state.loginUserInfo.signInUserSession = session
  // },
}

const actions: Actions = {
  startRequest: ({ commit }, Id: string) => {
    // console.log("startRequest", Id)
    commit('startLoading', Id)
  },
  endRequest: ({ commit }, Id: string) => {
    // console.log("endRequest", Id)
    commit('endLoading', Id)
  },

  alreadySignedIn: ({ commit }, user) => {
    commit('signin', user)
  },

  async changePassword({ dispatch, commit, state: AuthState }, passwordInfo) {
    const loadingId = 'changePassword'
    dispatch('startRequest', loadingId)
    const result = Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          passwordInfo.currentPassword,
          passwordInfo.newPassword
        )
      })
      .then((data) => console.log(data))
      .catch((error) => {
        console.log(`change password is failed: ${error.code}`)
        if (error.code === 'NotAuthorizedException') {
          throw Error(
            i18n.global.t('error.invalidChangePasswordNotAuth') as string
          )
        } else if (error.code === 'LimitExceededException') {
          throw Error(i18n.global.t('error.limitExceeded') as string)
        } else if (error.code === 'InvalidParameterException') {
          throw Error(i18n.global.t('error.invalidPassword') as string)
        } else if (error.code === 'InvalidPasswordException') {
          throw Error(i18n.global.t('error.InvalidPasswordException') as string)
        } else {
          throw error
        }
      })
    return result
  },

  async logout({ commit }) {
    try {
      const result = await Auth.signOut()
      onLogout()
      commit('logout')
      commit('app/logout', null, { root: true })
      router.go(0)
      return result
    } catch (e) {
      console.log(e)
      return e
    }
  },

  async checkAlreadyLogin({ commit }) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      commit('signin', user)
    } catch (e) {
      console.log(e)
      return e
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
