export const pathGroup: PathGroup = {
  s: { home: '/s', splitNum: 3, theme: '#f38e00' },
  h: { home: '/h', splitNum: 2, theme: '#444' },
  x: { home: '/x', splitNum: 3, theme: '#f38e00' },
  m: { home: '/m', splitNum: 3, theme: '#444' },
}
export type PathGroup = {
  [key: string]: { home: string; splitNum: number; theme: string }
}
/**
 * 権限チェック用のパターン
 */
export const AVAILABLE_PATH = {
  viewer: '/v/',
  maintenance: '/m/',
  shop: '/s/',
  head: '/h/',
}

// 絞り込み条件の選択上限数
export const SELECT_MAX_COUNT = 100

// 絞り込み条件の表示上限数
export const MAX_COUNT = 500

export const USER_SETTING_EXTRACT_OPTIONS = [
  { item: 'loginIdNotSet', name: 'ログインID未設定' },
  { item: 'isNotChangePassword', name: '初期パスワード未変更' },
  { item: 'isLoginNotPossible', name: 'ログイン不可' },
  { item: 'isLoginPossible', name: 'ログイン可能' },
]

interface LOGIN_ALLOWED {
  [key: string]: any
}
export const LOGIN_ALLOWED: LOGIN_ALLOWED = {
  0: 'ログイン不可',
  1: 'ログイン可能',
}
interface IS_CHANGE_PASSWORD {
  [key: string]: any
}
export const IS_CHANGE_PASSWORD: IS_CHANGE_PASSWORD = {
  0: '未変更',
  1: '変更済み',
}

export const LOGIN_ID_JOINT = '-@@-'
