// 参考： https://qiita.com/yoy119/items/61bced5a3d35a402a43c
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from 'vee-validate'
// @ts-ignore
import ja from 'vee-validate/dist/locale/ja'
import { required, integer, excluded, max } from 'vee-validate/dist/rules'
import { createApp } from 'vue'

localize('ja', ja)

// const app = createApp({})
// app.component('ValidationProvider', ValidationProvider)
// app.component('ValidationObserver', ValidationObserver)

// extend('required', {
//   ...required,
//   message: 'このフィールドに入力して下さい',
// })

// extend('integer', {
//   ...integer,
//   message: '整数を入力して下さい',
// })

// // No message specified.
// extend('excluded', excluded)

// // No message specified.
// extend('max', max)

// // 参考： https://qiita.com/someone7140/items/f453335db90e9871e6ec
// extend('passwordReInput', {
//   params: ['target'],
//   // @ts-ignore
//   validate(value, { target }) {
//     return value === target
//   },
//   message: '上のパスワード欄と同様のものを入力して下さい',
// })
