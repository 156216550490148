import { SetupContext } from '@vue/composition-api'
import { ApolloError } from 'apollo-client'
import { BvToast } from 'bootstrap-vue'
import { VNode, getCurrentInstance } from 'vue'
import { TranslateResult } from 'vue-i18n'

import { createApolloErrorMessage } from './apiError'

import i18n from '@/vue-i18n'

interface ToastParam {
  title: string
  variant: string
  position: string
  autoHideDelay: number
  noAutoHide: boolean
  static: boolean
  bodyClass: any
}
interface ToastImpl {
  toast: (message: string, toastParams: ToastParam) => void
}
interface Toast {
  $bvToast: ToastImpl
}

type ToastCustomArgs = {
  title?: string
  message: TranslateResult | string
  variant?: string
  position?: string
  autoHideDelay?: number
  noAutoHide?: boolean
}
function custom(
  self: Toast,
  {
    title = '',
    message = '',
    variant = 'primary',
    position = 'is-top-right',
    autoHideDelay = 5000,
    noAutoHide = false,
  }: ToastCustomArgs
) {
  // https://bootstrap-vue.org/docs/components/toast#toasts-on-demand
  // When passing options to this.$bvToast.toast(),
  // use the camelCase version of the component prop name,
  // i.e. use noAutoHide instead of no-auto-hide.
  const mes = message as string
  self.$bvToast.toast(mes, {
    title,
    variant,
    position,
    autoHideDelay: autoHideDelay,
    noAutoHide: noAutoHide,
    static: true,
    bodyClass: 'ws-pre',
  })
}

function success(
  self: Toast,
  { message = '' }: { message: TranslateResult | string }
) {
  custom(self, {
    title: '通知',
    message,
    variant: 'success',
    autoHideDelay: 3000,
  })
}

function warning(
  self: Toast,
  { message = '' }: { message: TranslateResult | string }
) {
  custom(self, {
    title: '警告',
    message,
    variant: 'warning',
    autoHideDelay: 6000,
  })
}

function error(
  self: Toast,
  { message = '' }: { message: TranslateResult | string }
) {
  custom(self, {
    title: 'エラー',
    message,
    variant: 'danger',
    autoHideDelay: 6000,
    noAutoHide: true,
  })
}
/**
 * ダイアログを表示
 * @param context
 * @param message
 * @param callback
 * @param errCallback
 */
type showDialogOption = {
  message: string | TranslateResult | VNode
  variant?: string
  title?: string | TranslateResult
  okTitle?: string
}
export function showDialog(context: SetupContext, options: showDialogOption) {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const instance = getCurrentInstance() as any
    setTimeout(() => {
      // The timeout seems to be need, otherwise _bv__toast is undefined.
      const bvToast = instance.ctx._bv__toast as BvToast
    }, 100)
    // @ts-ignore
    bvToast.msgBoxConfirm(options.message, {
      title: options.title ? options.title : '確認',
      okVariant: options.variant ? options.variant : 'danger',
      okTitle: options.okTitle ? options.okTitle : '確定',
      cancelTitle: 'キャンセル',
      cancelVariant: 'outline-dark',
      headerClass: 'p-2 border-bottom-0',
      footerClass: 'p-2 border-top-0',
      centered: true,
    })
    // .then((value) => {
    //   return resolve(value)
    // })
    // .catch((err) => {
    //   return reject()
    // })
  })
}

/**
 * 通知メッセージの生成
 */
export function genNotifyMessage(
  base = 'e.list',
  key = '',
  e?: ApolloError,
  keyPrefix = ''
): string {
  if (!base || !key) {
    return ''
  }
  console.log(e)
  const _key = i18n.global.t(`${keyPrefix}label.${key}`) as string
  let message = i18n.global.t(`notification.${base}`, [_key]) as string

  const isFailure = base.slice(0, 1) === 'e'
  if (isFailure && e) {
    message += '\r\n' + createApolloErrorMessage(e)
  }
  return message
}

export default {
  custom,
  success,
  warning,
  error,
}
