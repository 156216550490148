import AmplifyVue from '@aws-amplify/ui-vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
// import { createApolloProvider } from '@vue/apollo-option'
import './registerServiceWorker'
import './vee-validate'
// import { createProvider } from './vue-apollo'
import './dayjs'
import { Amplify } from 'aws-amplify'
import {
  BootstrapVue,
  IconsPlugin,
  ModalPlugin,
  CardPlugin,
  ToastPlugin,
  BModal,
} from 'bootstrap-vue'
import Vue, { createApp, provide, h } from 'vue'
import VueRouterLayout from 'vue-router-layout'

import App from './App.vue'
import amzConfig from './aws-exports'
// import VueVirtualScroller from 'vue-virtual-scroller'
import router from './router'
import store from './store'
import i18n from './vue-i18n'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import apolloProvider from '@/vue-apollo'
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import '@aws-amplify/ui-vue/styles.css'
// require('./assets/scss/global.scss')

// mock-apiをimportするとmockデータ取得
// import '../tests/mocks/mock-api'

// for amplify
Amplify.configure(amzConfig)

// Vue3では削除された
// Vue.config.productionTip = false

const app = createApp({
  setup() {
    // provide(DefaultApolloClient, apolloClient)
  },

  render: () => h(App),
})

app.use(router)
app.use(i18n)
app.use(store)
app.use(AmplifyVue)
// app.use(VueVirtualScroller)
app.use(BootstrapVue)
app.use(ToastPlugin)
app.use(ModalPlugin)
app.use(CardPlugin)
app.use(IconsPlugin)
app.use(apolloProvider)
app.use(VueRouterLayout)
app.component('b-modal', BModal)

app.mount('#app')
