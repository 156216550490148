import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_navbar_brand = _resolveComponent("b-navbar-brand")!
  const _component_b_navbar = _resolveComponent("b-navbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_navbar, {
      toggleable: "sm",
      type: "dark",
      style: {"background-color":"#444"},
      fixed: "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_navbar_brand, { class: "font-weight-bold" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.serviceName), 1)
          ], undefined, true),
          _: 1
        })
      ], undefined, true),
      _: 1
    })
  ]))
}