import { ApolloError } from 'apollo-client'
import dayjs from 'dayjs'
import { GraphQLError } from 'graphql'
import { ActionTree, GetterTree } from 'vuex'

import store from '..'
import {
  AppState,
  RootState,
  AppMutations,
  kigyoActionSetting,
  torihikisaki,
} from '../../types/entities'
import { apolloClient } from '../../vue-apollo'

import {
  GetSystemStatusQuery,
  SystemStatus,
  Riyosha,
  GetLoginUserDocument,
  GetSystemStatusDocument,
} from './../../types/generated/graphql'

// 推奨状況のキー これが異常の場合は推奨数異常でアラート
const EXEC_STATUS_RECOMMEND_KEY = 'out_recommend_status'

function getDefaultState() {
  return {
    loginUser: null,
    systemStatus: {
      isMaintenance: false,
      kigyoId: '',
    },
    kigyoActionSetting: {
      isEditRiyosha: false,
    },
    torihikisakiList: [],
    // loadingIds: [],
  }
}
export const state: AppState = getDefaultState()

export const mutations: AppMutations = {
  loginUser: (state: AppState, loginUser: Riyosha) => {
    state.loginUser = loginUser
  },
  kigyoActionSetting(state: AppState, kigyoActionSetting: kigyoActionSetting) {
    // @ts-ignore
    state.kigyoActionSetting = kigyoActionSetting
  },
  updateSystemStatus(state: AppState, systemStatus: SystemStatus) {
    // @ts-ignore
    state.systemStatus = systemStatus
  },
  logout: (state: AppState) => {
    Object.assign(state, getDefaultState())
  },
  torihikisakiList(state: AppState, torihikisakiList: torihikisaki[]) {
    state.torihikisakiList = torihikisakiList
  },
}

/**
 * 未認証用エラークラス
 * access token がない場合などはこちら
 */
class NotAuthorizedException extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'NotAuthorizedException'
  }
}

const actions: ActionTree<AppState, RootState> = {
  /**
   * サーバーの状態取得
   */
  async getSystemStatus({ dispatch, commit, rootState }) {
    // loginUserが取得できない時はurlからkigyoIdを取得した値を使用
    console.log('isSystemMaintenance')
    try {
      const result = await apolloClient.query<GetSystemStatusQuery>({
        query: GetSystemStatusDocument,
      })
      const { getSystemStatus } = result.data
      if (getSystemStatus && 'isMaintenance' in getSystemStatus) {
        commit('updateSystemStatus', getSystemStatus)
      } else {
        // 異常状態での返却値は異常扱い（メンテナンスモード移行）
        commit('updateSystemStatus', {
          isMaintenance: true,
        })
      }
      return result
    } catch (e: unknown) {
      throw e as ApolloError
    }
  },
}

const getters: GetterTree<AppState, RootState> = {
  /**
   * システムがメンテナンス状態か否か
   */
  isSystemMaintenance(state: AppState) {
    return state.systemStatus.isMaintenance
  },

  isAdmin(state: AppState) {
    if (!state.loginUser || !state.loginUser.userTypes) return false
    return state.loginUser.userTypes.includes('COMPANY_ADMIN')
  },

  isEditRiyosha(state: AppState) {
    return state.kigyoActionSetting.isEditRiyosha
  },
  torihikisakiList(state: AppState) {
    return state.torihikisakiList
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
